import axios from "axios";

type Options = {
  accessToken: string;
  jwtFormat: string;
};

export const setAxiosAuthorizationHeader = ({
  accessToken,
  jwtFormat,
}: Options) => {
  axios.defaults.headers.Authorization = `${jwtFormat} ${accessToken}`;
};
