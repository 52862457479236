import { createLogger } from "@/shared/utils/createLogger";

import { REFRESH_TOKEN_STORAGE_KEY } from "./consts";

const logger = createLogger("setRefreshToken");

export const setRefreshToken = (token: string) => {
  if (!token) {
    logger.warn("No refresh token provided cannot set it");
    return;
  }
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
};
