import axios from "axios";

import type { TTokenRefreshPayload, TTokenRefreshResponse } from "../types";

export const tokenRefresh = (payload: TTokenRefreshPayload) => {
  return axios.post<TTokenRefreshResponse>(
    "/api/v1/core/token/refresh/",
    payload,
  );
};
