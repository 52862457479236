import axios from "axios";
import { useCallback } from "react";

import { useInternalAuthContext } from "../providers/AuthProvider/AuthContext";
import { removeAccessToken } from "../utils/removeAccessToken";
import { removeRefreshToken } from "../utils/removeRefreshToken";

export type UseSignOutOptions = {
  onDone?: () => void;
};

export const useSignOut = (options?: UseSignOutOptions) => {
  const { setLoggedOut } = useInternalAuthContext();

  const { onDone } = options || {};

  const signOut = useCallback(async () => {
    removeRefreshToken();
    removeAccessToken();
    delete axios.defaults.headers?.Authorization;
    setLoggedOut();
    onDone?.();
  }, [onDone, setLoggedOut]);

  return {
    signOut,
  };
};
