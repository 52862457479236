import { createLogger } from "@/shared/utils/createLogger";

import { REFRESH_TOKEN_STORAGE_KEY } from "./consts";

const logger = createLogger("removeRefreshToken");

export const removeRefreshToken = () => {
  logger.info("Removing refresh token");
  return localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
};
