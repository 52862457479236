import { createLogger } from "@/shared/utils/createLogger";

import type { AppRoute } from "./types";

import { CivilianGuard } from "./guards/CivilianGuard";
import { DoctorGuard } from "./guards/DoctorGuard";
import { PrivateGuard } from "./guards/PrivateGuard";
import { UserRoleGuard } from "./guards/UserRoleGuard";
import { useAppRoute } from "./hooks/useAppRoute";

const logger = createLogger("guardRoute");

export type GuardedComponent = React.ComponentType<GuardProps>;

type GuardMap = {
  permission: ProtectedRoutes<NonNullable<AppRoute["permission"]>>;
  root: Array<GuardedComponent>;
  scope: ProtectedRoutes<NonNullable<AppRoute["scope"]>>;
};

type GuardProps = {
  element: React.ReactNode;
};

type ProtectedRoutes<T extends string> = Record<T, Array<GuardedComponent>>;

const guardMap: GuardMap = {
  permission: { all: [], civilian: [CivilianGuard], doctor: [DoctorGuard] },
  root: [UserRoleGuard],
  scope: { private: [PrivateGuard], public: [] },
};

export const guardRoute = (route: AppRoute): AppRoute => {
  const { children, element, excludedGuards = [], ...rest } = route;

  const guards = Object.keys(guardMap).reduce((acc, key) => {
    let foundGuards: Array<GuardedComponent> = [];

    if (key === "root") {
      foundGuards = guardMap.root;
    } else {
      // @ts-expect-error Typescript doesn't understand that route[key] is a string
      foundGuards = guardMap?.[key]?.[route?.[key]] as Array<GuardedComponent>;
    }

    if (foundGuards?.length) {
      const filteredGuards = foundGuards.filter(
        (guard) => !excludedGuards.includes(guard),
      );

      acc.push(...filteredGuards);

      if (process.env.NODE_ENV === "development") {
        logger.info(
          `Wrapped ${route.path} with ${filteredGuards.map((g) => g.name).join(", ")}`,
        );
      }
    }
    return acc;
  }, [] as Array<GuardedComponent>);

  if (excludedGuards.length && process.env.NODE_ENV === "development") {
    logger.info(
      `Bypassed ${route.path} with ${excludedGuards.map((g) => g.name).join(", ")}`,
    );
  }

  const Wrapper = guards.reduceRight(
    (Guard, NextGuard) => (props: GuardProps) => {
      const { appRoute } = useAppRoute();

      if (appRoute?.excludedGuards?.includes(NextGuard)) {
        return <Guard element={props.element} />;
      }

      return <Guard element={<NextGuard element={props.element} />} />;
    },
    ({ element }: GuardProps) => element,
  );

  return {
    ...rest,
    children: children?.map(guardRoute),
    element: <Wrapper element={element} />,
  } as AppRoute;
};
