export const joinPath = (...args: (number | string)[]) => {
  const protocolRegex = /^[a-zA-Z]+:\/\//;
  const [first, ...rest] = args;
  let value;

  if (typeof first === "string" && protocolRegex.test(first)) {
    value =
      first.replace(/\/+$/, "") + "/" + rest.join("/").replace(/\/+/g, "/");
  } else {
    value = [first, ...rest].join("/").replace(/\/+/g, "/");
  }

  if (value.endsWith("/")) {
    return value.replace(/\/+$/, "/");
  } else {
    return value;
  }
};
