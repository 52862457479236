import { useAuthContext } from "@/base/auth/providers/AuthProvider";
import { Navigate } from "react-router-dom";

import type { GuardedComponent } from "../guardRoute";

import { appRoutePaths } from "../routes";

export const PrivateGuard: GuardedComponent = ({ element }) => {
  const { isLoggedIn } = useAuthContext();

  if (!isLoggedIn) {
    return <Navigate replace={true} to={appRoutePaths.signIn} />;
  }

  return element;
};
