import type { ContainerProps } from "@chakra-ui/react";

import { Container, Spinner } from "@chakra-ui/react";
import { createContext, useContext } from "react";
import { Helmet } from "react-helmet";

type LayoutContextProps = {
  productName: string;
};

const LayoutContext = createContext<LayoutContextProps>({
  productName: "",
});

export const LayoutProvider = LayoutContext.Provider;

const useLayoutContext = () => useContext(LayoutContext);

type LayoutProps = {
  children?: React.ReactNode;
  isLoading?: boolean;
  metaTitle?: MetaTitle | MetaTitle[];
} & ContainerProps;

type MetaTitle = null | string | undefined;

export const Layout = ({
  centerContent,
  children,
  isLoading,
  maxW,
  maxWidth,
  metaTitle,
  ...containerProps
}: LayoutProps) => {
  const { productName } = useLayoutContext();

  const formattedTitle =
    metaTitle instanceof Array
      ? metaTitle.filter(Boolean).join(" - ")
      : metaTitle;
  const negotiatedTitle = [formattedTitle, productName]
    .filter(Boolean)
    .join(" | ");

  return (
    <>
      <Helmet title={negotiatedTitle} />
      <Container
        alignItems={centerContent || isLoading ? "center" : undefined}
        centerContent={isLoading || centerContent}
        flex={1}
        justifyContent={centerContent || isLoading ? "center" : undefined}
        maxWidth={maxWidth || maxW || "container.lg"}
        my={2}
        {...containerProps}
      >
        {isLoading ? <Spinner size="xl" /> : children}
      </Container>
    </>
  );
};
