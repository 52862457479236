import type { Reducer } from "react";

import type { AuthActionTypes } from "./actions";

export type AuthState = {
  accessToken?: null | string;
  isLoggedIn: boolean;
  isTokenHandled: boolean;
};

export const initialAuthState: AuthState = {
  accessToken: undefined,
  isLoggedIn: false,
  isTokenHandled: false,
};

export const authReducer: Reducer<AuthState, AuthActionTypes> = (
  state = initialAuthState,
  action,
): AuthState => {
  switch (action.type) {
    case "SET_IS_TOKEN_HANDLED":
      return {
        ...state,
        isTokenHandled: true,
      };
    case "SET_LOGGED_IN":
      return {
        ...state,
        accessToken: action.meta?.data.accessToken,
        isLoggedIn: true,
      };
    case "SET_LOGGED_OUT":
      return {
        ...state,
        accessToken: null,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
