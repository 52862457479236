import { useCurrentUser } from "@/features/core/current-user/hooks/useCurrentUser";
import { Spinner } from "@/shared/components/Spinner";
import { Navigate } from "react-router-dom";
import { generatePath } from "react-router-dom";

import type { GuardedComponent } from "../guardRoute";

import { appRoutePaths } from "../routes";

export const DoctorGuard: GuardedComponent = ({ element }) => {
  const { data, isLoading } = useCurrentUser();

  if (isLoading) {
    return <Spinner absoluteCenter={true} />;
  }

  if (!data?.role.includes("doctor")) {
    return <Navigate to={generatePath(appRoutePaths.root)} />;
  }

  return element;
};
