import { createLogger } from "@/shared/utils/createLogger";

import { ACCESS_TOKEN_STORAGE_KEY } from "./consts";

const logger = createLogger("removeAccessToken");

export const removeAccessToken = () => {
  logger.info("Removing access token");
  return localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
};
