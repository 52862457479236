import type { JSX } from "react";

import { Box, Spinner as ChakraSpinner } from "@chakra-ui/react";

export type SpinnerProps = {
  absoluteCenter?: boolean;
  fullscreen?: boolean;
} & JSX.IntrinsicElements["div"];

export const Spinner = ({
  absoluteCenter,
  fullscreen,
  ...divProps
}: SpinnerProps) => {
  if (fullscreen) {
    return (
      <Box
        alignItems="center"
        backgroundColor="rgba(255, 255, 255, 0.8)"
        display="flex"
        height="100%"
        justifyContent="center"
        left="0"
        position="fixed"
        top="0"
        width="100%"
        zIndex="9999"
        {...divProps}
      >
        <ChakraSpinner size="xl" />
      </Box>
    );
  }

  if (absoluteCenter) {
    return (
      <Box
        left="50%"
        position="absolute"
        top="50%"
        transform="translate(-50%, -50%)"
        zIndex="9999"
        {...divProps}
      >
        <ChakraSpinner size="xl" />
      </Box>
    );
  }

  return <ChakraSpinner size="xl" {...divProps} />;
};
