import { createLogger } from "@/shared/utils/createLogger";

import type { JWTDecodeData } from "./jwtDecode";

import { jwtDecode } from "./jwtDecode";

const logger = createLogger("isTokenValid");

type TokenValidResponse = {
  error: null | unknown;
  isValid: boolean;
  token: null | string;
};

export const isTokenValid = (token?: null | string): TokenValidResponse => {
  if (!token) {
    logger.info("Token is not provided");
    return { error: null, isValid: false, token: null };
  }
  try {
    const decoded = jwtDecode<JWTDecodeData>(token);
    const now = Date.now() / 1000;
    const isValid = decoded.exp > now;
    logger.info("Token is valid", { isValid });
    return { error: null, isValid, token: isValid ? token : null };
  } catch (error) {
    logger.error("Token is invalid", { error });
    return { error, isValid: false, token: null };
  }
};
