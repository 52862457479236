import { createLogger } from "@/shared/utils/createLogger";

import { getRefreshToken } from "./getRefreshToken";
import { isTokenValid } from "./isTokenValid";

const logger = createLogger("isRefreshTokenValid");

export const isRefreshTokenValid = async () => {
  logger.info("Checking if refresh token is valid");
  return isTokenValid(getRefreshToken());
};
