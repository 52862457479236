import { createLogger } from "@/shared/utils/createLogger";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

const logger = createLogger("initSentry");

const IGNORED_REQUEST_STATUSES = [401, 403, 404, 422];

export const initSentry = () =>
  Sentry.init({
    beforeSend: (event, hint) => {
      const { originalException } = hint;
      if (originalException instanceof AxiosError) {
        const { response } = originalException;
        if (response && IGNORED_REQUEST_STATUSES.includes(response.status)) {
          logger.debug("Ignoring non-fatal error", response);
          return null;
        }
      }
      return event;
    },
    dsn: import.meta.env.VITE_SENTRY_DSN,
    enabled: import.meta.env.SENTRY_ENABLED,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1.0,
  });
