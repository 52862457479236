import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { useAuthContext } from "@/base/auth/providers/AuthProvider";
import { paramsSerializer } from "@/shared/utils/paramsSerializer";
import { useQuery as tanstackUseQuery } from "@tanstack/react-query";
import axios from "axios";

export type UseQueryProps<T> = {
  headers?: Record<string, string>;
  queryKey: QueryKey;
  transform?: <U = T>(data: T) => Promise<T | U>;
  url: string;
  urlParams?: Record<string, unknown>;
  withAuthorization?: boolean;
} & UseQueryOptions<T>;

export const useQuery = <T>({
  headers,
  queryKey,
  transform,
  url,
  urlParams,
  withAuthorization = true,
  ...options
}: UseQueryProps<T>) => {
  const { accessToken } = useAuthContext();
  return tanstackUseQuery({
    queryFn: async () => {
      const queryHeaders: Record<string, string> = {
        ...headers,
      };

      if (withAuthorization && accessToken) {
        queryHeaders.Authorization = `Bearer ${accessToken}`;
      }

      const { data } = await axios.get<T>(url, {
        headers: queryHeaders,
        params: urlParams,
        paramsSerializer,
      });
      return transform ? transform(data) : data;
    },
    queryKey,
    ...options,
  });
};
