import { isAxiosError } from "axios";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useMemo } from "react";

import { isAccessTokenValid } from "../utils/isAccessTokenValid";
import { isRefreshTokenValid } from "../utils/isRefreshTokenValid";
import { setAccessToken } from "../utils/setAccessToken";
import { setAccessTokenTimeout } from "../utils/setAccessTokenTimeout";
import { setRefreshToken } from "../utils/setRefreshToken";
import { use401Interceptor } from "./use401Interceptor";
import { useSignOut } from "./useSignOut";

export type UseJWTOptions = {
  getTokensFn: GetTokensFn;
  isLoggedIn: boolean;
  onError?: (error: unknown) => void;
  onLoggedIn: (accessToken: string) => void;
  onLoggedOut: () => void;
  onTokenHandled: () => void;
};

type GetAndSetNewRefreshTokensFnOptions = { refresh: string };

type GetTokensFn = (payload: { refresh: string }) => Promise<{
  data: {
    access: string;
    refresh: string;
  };
}>;

export const useJWT = ({
  getTokensFn,
  isLoggedIn,
  onError,
  onLoggedIn,
  onLoggedOut,
  onTokenHandled,
}: UseJWTOptions) => {
  const { signOut } = useSignOut();

  const getTokensWithRefreshToken = useMemo(
    () =>
      debounce(
        async ({ refresh }: GetAndSetNewRefreshTokensFnOptions) => {
          try {
            const { data } = await getTokensFn({ refresh });
            setAccessToken(data.access);
            setRefreshToken(data.refresh);
            onLoggedIn(data.access);
          } catch (error) {
            onError?.(error);
            if (isAxiosError(error) && error.response?.status === 401) {
              signOut();
            }
          }
        },
        2000,
        { leading: true, trailing: false },
      ),
    [getTokensFn, onError, onLoggedIn, signOut],
  );

  const getTokensWithExistingRefreshToken = useCallback(async () => {
    const { isValid, token } = await isRefreshTokenValid();
    if (isValid && token) {
      await getTokensWithRefreshToken({ refresh: token });
    } else {
      onLoggedOut();
    }
  }, [getTokensWithRefreshToken, onLoggedOut]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const onFocus = () => {
      const { isValid, token } = isAccessTokenValid();
      if (isValid && token) {
        onLoggedIn(token);
      } else {
        getTokensWithExistingRefreshToken();
      }
    };

    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [getTokensWithExistingRefreshToken, isLoggedIn, onLoggedIn]);

  use401Interceptor({
    on401: getTokensWithExistingRefreshToken,
    skip: !isLoggedIn,
  });

  useEffect(() => {
    if (isLoggedIn) {
      return;
    }

    (async () => {
      const { isValid, token } = isAccessTokenValid();
      if (isValid && token) {
        onLoggedIn(token);
      } else {
        await getTokensWithExistingRefreshToken();
      }

      onTokenHandled();
    })();
  }, [
    getTokensWithExistingRefreshToken,
    isLoggedIn,
    onLoggedIn,
    onTokenHandled,
  ]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    let timeout: number | undefined;

    const setupTimeout = () => {
      return setAccessTokenTimeout(async ({ refresh }) => {
        await getTokensWithRefreshToken({ refresh });
        timeout = await setupTimeout();
      });
    };

    (async () => {
      timeout = await setupTimeout();
    })();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [getTokensWithRefreshToken, isLoggedIn]);
};
