import type { AppRoute } from "@/base/router/types";

import { lazy } from "react";

const _RootView = lazy(() => import("./views/RootView"));
const _PageNotFoundView = lazy(() => import("./views/PageNotFoundView"));

export const rootRoutePaths = {
  root: "/",
} as const;

export const rootRoutes: AppRoute[] = [
  {
    element: <_RootView />,
    enabled: true,
    path: rootRoutePaths.root,
    root: true,
  },
  {
    element: <_PageNotFoundView />,
    enabled: true,
    path: "*",
    permission: "all",
    presentation: "fullscreen",
    root: false,
    scope: "public",
  },
];
