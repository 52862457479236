import { createLogger } from "@/shared/utils/createLogger";

import { ACCESS_TOKEN_STORAGE_KEY } from "./consts";

const logger = createLogger("setAccessToken");

export const setAccessToken = (token: string) => {
  if (!token) {
    logger.warn("Token is empty will not set it");
    return;
  }
  logger.info("Setting access token", token);
  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
};
