const emojiMap = {
  debug: "🐛",
  error: "🚨",
  info: "💡",
  log: "📝",
  warn: "⚠️",
};

const buildPrefix = (
  type: "debug" | "error" | "info" | "log" | "warn",
  prefix: string,
) => `${emojiMap[type]} [${prefix}]`;

export const createLogger = (prefix: string) => {
  const enabled = process.env.NODE_ENV === "development";
  return {
    debug: (...args: unknown[]) =>
      enabled && console.debug(buildPrefix("debug", prefix), ...args),
    error: (...args: unknown[]) =>
      enabled && console.error(buildPrefix("error", prefix), ...args),
    info: (...args: unknown[]) =>
      enabled && console.info(buildPrefix("info", prefix), ...args),
    log: (...args: unknown[]) =>
      enabled && console.log(buildPrefix("log", prefix), ...args),
    warn: (...args: unknown[]) =>
      enabled && console.warn(buildPrefix("warn", prefix), ...args),
  };
};
