import { createLogger } from "@/shared/utils/createLogger";

import { getAccessToken } from "./getAccessToken";
import { isTokenValid } from "./isTokenValid";

const logger = createLogger("isAccessTokenValid");

export const isAccessTokenValid = () => {
  logger.info("Checking if access token is valid");
  return isTokenValid(getAccessToken());
};
