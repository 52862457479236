import { matchPath, useLocation } from "react-router-dom";

import { enabledRoutes } from "../routes";

export const useAppRoute = () => {
  const location = useLocation();
  const appRoute = enabledRoutes.find((route) =>
    matchPath(route.path, location.pathname),
  );
  return { appRoute };
};
