import { useQuery } from "@/shared/hooks/useQuery";

import type { TLanguage } from "../types";

export const useLanguages = () => {
  return useQuery<TLanguage[]>({
    queryKey: ["languages"],
    url: "/api/v1/i18n/languages/",
  });
};
