import { AuthProvider } from "@/base/auth/providers/AuthProvider";
import { I18nLoader } from "@/base/i18n/components/I18nLoader";
import { AppRoutes } from "@/base/router/AppRoutes";
import { tokenRefresh } from "@/features/core/token/api/tokenRefresh";
import { LayoutProvider } from "@/shared/components/Layout";
import { Toaster } from "@/shared/components/Toaster";
import { BrowserRouter } from "react-router-dom";

const App = () => (
  <AuthProvider getTokensFn={tokenRefresh}>
    <I18nLoader>
      <Toaster />
      <LayoutProvider
        value={{ productName: import.meta.env.VITE_PRODUCT_NAME }}
      >
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </LayoutProvider>
    </I18nLoader>
  </AuthProvider>
);

export default App;
