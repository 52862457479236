import { isEmpty } from "./isEmpty";
import { isNil } from "./isNil";
import { pickBy } from "./pickBy";

export const paramsSerializer = (params: Record<string, unknown>) => {
  const cleanedObject = pickBy(
    params,
    (value) => !isNil(value) && !isEmpty(value),
  );
  return new URLSearchParams(
    cleanedObject as Record<string, string>,
  ).toString();
};
