import { rootRoutePaths, rootRoutes } from "@/base/root/routes";
import { coreRoutePaths, coreRoutes } from "@/features/core/routes";

import { guardRoute } from "./guardRoute";

const routePaths = {
  ...rootRoutePaths,
  ...coreRoutePaths,
} as const;

export const enabledRoutes = [...coreRoutes, ...rootRoutes].filter(
  (route) => route.enabled,
);

const enabledRootRoute = enabledRoutes.find((route) => route.root);

if (!enabledRootRoute) {
  throw new Error("No root route found.");
}

export const rootRoute = guardRoute(enabledRootRoute);

rootRoute.children = enabledRoutes
  .filter((route) => !route.root)
  .map(guardRoute);

type AppRoutePaths = {
  [K in keyof typeof routePaths]: (typeof routePaths)[K];
};

export const appRoutePaths = Object.entries(routePaths).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: value,
  }),
  {} as AppRoutePaths,
);
