import type { AppRoute } from "@/base/router/types";

import { UserRoleGuard } from "@/base/router/guards/UserRoleGuard";
import { lazy } from "react";

const _SignInView = lazy(() => import("./sign-in/views/SignInView"));
const _SignOutView = lazy(() => import("./sign-out/views/SignOutView"));
const _SignUpView = lazy(() => import("./sign-up/views/SignUpView"));
const _ValidateVerificationView = lazy(
  () => import("./validate-verification/views/ValidateVerificationView"),
);
const _ResetPasswordView = lazy(
  () => import("./reset-password/views/ResetPasswordView"),
);
const _ChangePasswordView = lazy(
  () => import("./current-user/views/ChangePasswordView"),
);
const _ChangeEmailView = lazy(
  () => import("./current-user/views/ChangeEmailView"),
);
const _ChangePhoneNumberView = lazy(
  () => import("./current-user/views/ChangePhoneNumberView"),
);
const _ChooseUserRoleView = lazy(
  () => import("./current-user/views/ChooseUserRoleView"),
);

export const coreRoutePaths = {
  changeEmail: "/change-email",
  changePassword: "/change-password",
  changePhoneNumber: "/change-phone-number",
  chooseUserRole: "/choose-user-role",
  resetPassword: "/reset-password",
  signIn: "/sign-in",
  signOut: "/sign-out",
  signUp: "/sign-up",
  validateVerification: "/validate-verification/:kind/:phone_number",
} as const;

export const coreRoutes: AppRoute[] = [
  {
    element: <_SignInView />,
    enabled: true,
    path: coreRoutePaths.signIn,
    permission: "all",
    presentation: "fullscreen",
    scope: "public",
  },
  {
    element: <_SignOutView />,
    enabled: true,
    excludedGuards: [UserRoleGuard],
    path: coreRoutePaths.signOut,
    permission: "all",
    presentation: "fullscreen",
    scope: "public",
  },
  {
    element: <_SignUpView />,
    enabled: true,
    path: coreRoutePaths.signUp,
    permission: "all",
    presentation: "fullscreen",
    scope: "public",
  },
  {
    element: <_ValidateVerificationView />,
    enabled: true,
    path: coreRoutePaths.validateVerification,
    permission: "all",
    presentation: "fullscreen",
    scope: "public",
  },
  {
    element: <_ResetPasswordView />,
    enabled: true,
    path: coreRoutePaths.resetPassword,
    permission: "all",
    presentation: "fullscreen",
    scope: "public",
  },
  {
    element: <_ChangePasswordView />,
    enabled: true,
    path: coreRoutePaths.changePassword,
    permission: "all",
    presentation: "fullscreen",
    scope: "private",
  },
  {
    element: <_ChangeEmailView />,
    enabled: true,
    path: coreRoutePaths.changeEmail,
    permission: "all",
    presentation: "fullscreen",
    scope: "private",
  },
  {
    element: <_ChangePhoneNumberView />,
    enabled: true,
    path: coreRoutePaths.changePhoneNumber,
    permission: "all",
    presentation: "fullscreen",
    scope: "private",
  },
  {
    element: <_ChooseUserRoleView />,
    enabled: true,
    excludedGuards: [UserRoleGuard],
    path: coreRoutePaths.chooseUserRole,
    permission: "all",
    presentation: "fullscreen",
    scope: "private",
  },
];
