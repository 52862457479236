export type AuthActionTypes =
  | ReducerAction<"SET_IS_TOKEN_HANDLED", null>
  | ReducerAction<"SET_LOGGED_IN", SetLoggedInActionMetaData>
  | ReducerAction<"SET_LOGGED_OUT", null>;

type SetLoggedInActionMetaData = {
  accessToken: string;
};

export const setLoggedInAction = ({
  accessToken,
}: SetLoggedInActionMetaData): AuthActionTypes => {
  return {
    meta: {
      data: {
        accessToken,
      },
    },
    type: "SET_LOGGED_IN",
  };
};

export const setLoggedOutAction = (): AuthActionTypes => {
  return {
    meta: { data: null },
    type: "SET_LOGGED_OUT",
  };
};

export const setIsTokenHandledAction = (): AuthActionTypes => {
  return {
    meta: { data: null },
    type: "SET_IS_TOKEN_HANDLED",
  };
};
