import { initSentry } from "@/base/monitoring/utils/initSentry.ts";
import {
  ChakraProvider,
  createSystem,
  defaultConfig,
  defineConfig,
} from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import i18next from "i18next";
import { createRoot } from "react-dom/client";

import App from "./App";

initSentry();

const root = document.getElementById("root");

if (!root) {
  throw new Error("Root element not found");
}

axios.defaults.baseURL = import.meta.env.VITE_PUBLIC_API_URL;

i18next.on("languageChanged", (lng) => {
  axios.defaults.headers["Accept-Language"] = lng;
});

const customConfig = defineConfig({
  globalCss: {
    "html, body, #root": {
      display: "flex",
      flexFlow: "column",
      minHeight: "100vh",
    },
  },
});

const system = createSystem(defaultConfig, customConfig);

const queryClient = new QueryClient();

createRoot(root).render(
  <QueryClientProvider client={queryClient}>
    <ChakraProvider value={system}>
      <App />
    </ChakraProvider>
  </QueryClientProvider>,
);
