import { useAuthContext } from "@/base/auth/providers/AuthProvider";
import { useQuery } from "@/shared/hooks/useQuery";

import type { TUserProfile } from "../types";

export const CURRENT_USER_QUERY_KEY = ["currentUser"] as const;

export const useCurrentUser = () => {
  const { isLoggedIn } = useAuthContext();
  return useQuery<TUserProfile>({
    enabled: isLoggedIn,
    queryKey: CURRENT_USER_QUERY_KEY,
    url: "/api/v1/core/current-user/",
  });
};
