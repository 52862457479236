import { create } from "zustand";

import type { TUserProfileRole } from "./types";

const CURRENT_USER_ROLE_STORAGE_KEY = "#core#current-user#role";

const getInitialActiveUserRole = () => {
  try {
    return localStorage.getItem(
      CURRENT_USER_ROLE_STORAGE_KEY,
    ) as TUserProfileRole;
  } catch {
    return undefined;
  }
};

type State = {
  activeUserRole?: TUserProfileRole;
  setActiveUserRole: (id: TUserProfileRole) => void;
  unsetActiveUserRole: () => void;
};

export const currentUserStore = create<State>((set) => ({
  activeUserRole: getInitialActiveUserRole(),
  setActiveUserRole: (role) => {
    localStorage.setItem(CURRENT_USER_ROLE_STORAGE_KEY, role);
    return set({ activeUserRole: role });
  },
  unsetActiveUserRole: () => {
    localStorage.removeItem(CURRENT_USER_ROLE_STORAGE_KEY);
    return set({ activeUserRole: undefined });
  },
}));
