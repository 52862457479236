import { createLogger } from "@/shared/utils/createLogger";

import type { JWTDecodeData } from "./jwtDecode";

import { getAccessToken } from "./getAccessToken";
import { getRefreshToken } from "./getRefreshToken";
import { isRefreshTokenValid } from "./isRefreshTokenValid";
import { isTokenValid } from "./isTokenValid";
import { jwtDecode } from "./jwtDecode";

const logger = createLogger("setAccessTokenTimeout");

const MAX_TIMEOUT = 2147483647;

type SetAccessTokenTimeoutCallback = (options: { refresh: string }) => void;

export const setAccessTokenTimeout = async (
  callback: SetAccessTokenTimeoutCallback,
): Promise<number | undefined> => {
  const accessToken = await getAccessToken();

  if (!accessToken || !isTokenValid(accessToken)) {
    logger.warn("Access token is not valid cannot set timeout");
    return;
  }

  const gap = 10000;
  const decoded = jwtDecode<JWTDecodeData>(accessToken);
  const ms = Math.min(
    new Date(decoded.exp * 1000).getTime() - Date.now() - gap,
    MAX_TIMEOUT,
  );

  logger.info("Setting access token timeout", { ms });

  return window.setTimeout(async () => {
    const refresh = getRefreshToken();
    if ((await isRefreshTokenValid()) && refresh) {
      callback({ refresh });
    }
  }, ms);
};
