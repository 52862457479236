import { useCurrentUser } from "@/features/core/current-user/hooks/useCurrentUser";
import { Spinner } from "@/shared/components/Spinner/";
import { joinPath } from "@/shared/utils/joinPath";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";

import { useLanguages } from "../../hooks/useLanguages";

const FALLBACK_LANGUAGE = "en";

export const I18nLoader: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [supportedLngs, setSupportedLngs] = useState<string[]>([]);
  const currentUserQuery = useCurrentUser();
  const languagesQuery = useLanguages();

  const userLanguage = currentUserQuery.data?.serialized_data?.language?.code;

  useEffect(() => {
    if (languagesQuery.isLoading) {
      return;
    }
    const languages =
      languagesQuery.data?.map((language) => language.code) || [];
    setSupportedLngs(languages);
  }, [languagesQuery.data, languagesQuery.isLoading]);

  useEffect(() => {
    if (currentUserQuery.isLoading || !supportedLngs.length) {
      return;
    }

    (async () => {
      await i18next
        .use(HttpApi)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          backend: {
            loadPath: joinPath(
              import.meta.env.VITE_PUBLIC_API_URL,
              "api/v1/i18n/languages/{{lng}}/translations/",
            ),
          },
          detection: {
            caches: ["localStorage"],
            order: ["querystring", "localStorage", "navigator"],
          },
          fallbackLng: FALLBACK_LANGUAGE,
          interpolation: {
            escapeValue: false,
          },
          lng: userLanguage || undefined,
          react: {
            useSuspense: false,
          },
          supportedLngs: supportedLngs,
        });

      setIsLoaded(true);
    })();
  }, [
    currentUserQuery.isLoading,
    languagesQuery.isLoading,
    supportedLngs,
    userLanguage,
  ]);

  if (!isLoaded) {
    return <Spinner absoluteCenter={true} />;
  }

  return children;
};
