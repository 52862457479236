import { useAuthContext } from "@/base/auth/providers/AuthProvider";
import { useCurrentUser } from "@/features/core/current-user/hooks/useCurrentUser";
import { currentUserStore } from "@/features/core/current-user/store";
import { Spinner } from "@/shared/components/Spinner";
import { generatePath, Navigate } from "react-router-dom";
import { useStore } from "zustand";

import type { GuardedComponent } from "../guardRoute";

import { appRoutePaths } from "../routes";

export const UserRoleGuard: GuardedComponent = ({ element }) => {
  const { isLoggedIn } = useAuthContext();
  const { isLoading } = useCurrentUser();
  const { activeUserRole } = useStore(currentUserStore);

  if (!isLoggedIn) {
    return element;
  }

  if (isLoading) {
    return <Spinner absoluteCenter={true} />;
  }

  if (!activeUserRole) {
    return <Navigate to={generatePath(appRoutePaths.chooseUserRole)} />;
  }

  return element;
};
