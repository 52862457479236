import { createContext, useContext } from "react";

import type { AuthState } from "./reducer";

import { initialAuthState } from "./reducer";

const noop = () => {
  return;
};

export const ExternalAuthContext = createContext<{
  accessToken?: null | string;
  isLoggedIn: boolean;
  jwtFormat: string;
}>({
  accessToken: null,
  isLoggedIn: false,
  jwtFormat: "Bearer",
});

export const InternalAuthContext = createContext<{
  authState: AuthState;
  setLoggedIn: (accessToken: string) => void;
  setLoggedOut: () => void;
}>({
  authState: initialAuthState,
  setLoggedIn: noop,
  setLoggedOut: noop,
});

export const useAuthContext = () => useContext(ExternalAuthContext);
export const useInternalAuthContext = () => useContext(InternalAuthContext);
