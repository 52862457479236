import axios from "axios";
import { useEffect } from "react";

export type Use401InterceptorOptions = {
  on401: () => void;
  skip?: boolean;
};

export const use401Interceptor = ({
  on401,
  skip,
}: Use401InterceptorOptions) => {
  useEffect(() => {
    if (skip) {
      return;
    }

    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          on401();
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      },
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [on401, skip]);
};
